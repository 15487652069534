* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Base font sizes */
:root {
  /* Desktop sizes */
  --font-size-h1: 45px;     /* Page titles: 35-50px */
  --font-size-h2: 35px;     /* Section headers: 25-35px */
  --font-size-h3: 24px;     /* Subsection headers: 20-25px */
  --font-size-body: 16px;   /* Body text: 16-20px */
  --font-size-small: 14px;  /* Small text: 14-16px */

  /* Mobile sizes */
  --font-size-h1-mobile: 32px;    /* Mobile titles: 28-40px */
  --font-size-h2-mobile: 28px;    /* Mobile headers: 22-30px */
  --font-size-h3-mobile: 20px;    /* Mobile subheaders: 18-24px */
  --font-size-body-mobile: 16px;  /* Mobile body: 16-18px */
} 