.home {
  padding-top: 0;
}

/* Hero Section */
.hero {
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  position: relative;
  overflow: hidden;
  background: white;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  z-index: 2;
}

.hero h1 {
  font-size: var(--font-size-h1);
  line-height: 1.2;
  margin-bottom: 2rem;
  color: #333;
  text-shadow: none;
}

.highlight {
  color: var(--primary-start);
  position: relative;
}

.hero p {
  font-size: var(--font-size-body);
  color: #666;
  margin-bottom: 2.5rem;
  line-height: 1.6;
  text-shadow: none;
}

/* Vimeo Hero Video Styling */
.vimeo-hero-container {
  margin: 0 auto 2rem;
  position: relative;
  width: 100%;
  max-width: 800px;
}

.hero-books {
  flex: 1;
  max-width: 600px;
}

.hero-books-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 600;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.hero-books-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 2px;
  background: linear-gradient(90deg, var(--primary-start), var(--primary-end));
}

.title-hot {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
}

.title-and {
  color: #777;
  font-weight: 400;
  margin: 0 4px;
}

.title-new {
  background: linear-gradient(45deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
}

.fire-icon {
  color: #ff4e50;
  animation: flame 0.7s infinite alternate;
}

.star-icon {
  color: #00c6ff;
  animation: twinkle 1.5s infinite;
}

@keyframes twinkle {
  0%, 100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.books-showcase {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}

.book-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 180px;
}

.book-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 2/3;
  transition: transform 0.3s ease;
}

.book-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: block;
}

.hot-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 0 2px 10px rgba(255, 78, 80, 0.3);
  animation: pulse 2s infinite;
  z-index: 2;
}

.hot-badge i {
  font-size: 1rem;
  animation: flame 0.8s infinite alternate;
  color: #fff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flame {
  from {
    transform: translateY(0) rotate(-5deg);
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
  }
  to {
    transform: translateY(-2px) rotate(5deg);
    text-shadow: 0 0 12px rgba(255, 255, 255, 0.8);
  }
}

.cta-button {
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,123,255,0.3);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,123,255,0.4);
  background: linear-gradient(45deg, #0056b3, #007bff);
}

@media (max-width: 1200px) {
  .book-cover {
    width: 150px;
    height: 225px;
  }
}

@media (max-width: 992px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 4rem 1rem;
  }

  .hero-content {
    max-width: 100%;
  }

  .hero h1 {
    font-size: var(--font-size-h1-mobile);
  }

  .vimeo-hero-container {
    max-width: 680px;
    margin: 0 auto 2rem;
    width: 100%;
  }

  .books-showcase {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .book-cover {
    width: 120px;
    height: 180px;
  }

  .hero h1 {
    font-size: var(--font-size-h1-mobile);
  }
  
  .hero p {
    font-size: var(--font-size-body-mobile);
  }

  .vimeo-hero-container {
    max-width: 100%;
  }

  .books-showcase {
    gap: 15px;
  }

  .book-column {
    width: 140px;
  }

  .badge-tag {
    padding: 4px 8px;
    font-size: 0.7rem;
  }
}

@media (max-width: 576px) {
  .books-showcase {
    flex-wrap: wrap;
  }

  .book-column {
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  .book-column {
    width: 120px;
  }
}

/* Publishing Section - Updated to "Who is talking about" section */
.publishing {
  padding: 4rem 0;
  text-align: center;
  background: #ffffff;
  width: 100%;
}

.publishing h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.publishing p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.brand-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.brand-logos img {
  height: 80px;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.brand-logos img:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .brand-logos {
    gap: 4rem;
  }
  
  .brand-logos img {
    height: 60px;
  }
  
  .publishing h2 {
    font-size: 2.2rem;
  }
}

/* About Section */
.about {
  padding: 4rem 2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.gradient-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.gradient-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Common Styles */
h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.cta-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background: #0056b3;
}

.learn-more {
  background: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.learn-more:hover {
  background: #007bff;
  color: white;
}

/* Book Showcase Section - Updated Styles */
.book-showcase {
  padding: 6rem 0;
  background: #f8f9fa;
  text-align: center;
}

.section-header {
  margin-bottom: 4rem;
}

.section-header h2 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
  font-weight: 600;
}

.section-header p {
  font-size: 1.2rem;
  color: #666;
}

.carousel-container {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  padding: 0 4rem;
}

.carousel {
  overflow: hidden;
  position: relative;
  height: 550px;
}

.carousel-slide {
  position: absolute;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.book-triple {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

.featured-book-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 300px;
}

.featured-book-card:hover {
  transform: translateY(-10px);
}

.book-image-container {
  width: 100%;
  height: 410px;
  overflow: hidden;
}

.featured-book-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid #eee;
}

.featured-book-info {
  padding: 1.5rem;
  text-align: center;
  height: 90px;
}

.featured-book-info h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.featured-book-info p {
  color: #666;
  font-size: 1rem;
}

.carousel-arrow {
  background: var(--gradient-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  border: none;
  z-index: 2;
}

.carousel-arrow svg {
  color: white;
  font-size: 20px;
}

.carousel-arrow.prev {
  left: 10px;
}

.carousel-arrow.next {
  right: 10px;
}

.carousel-arrow:hover {
  background: var(--hover-gradient);
  transform: translateY(-50%) scale(1.05);
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 2rem;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background: var(--primary-start);
  transform: scale(1.2);
}

@media (max-width: 1200px) {
  .featured-book-card {
    width: 250px;
  }
  
  .book-image-container {
    height: 350px;
  }
}

@media (max-width: 992px) {
  .book-triple {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .featured-book-card {
    width: 280px;
  }

  .carousel {
    height: 1200px;
  }
}

@media (max-width: 576px) {
  .carousel-container {
    padding: 0 2rem;
  }
}

/* Authors Section */
.authors {
  padding: 6rem 2rem;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.authors-header {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 3rem;
}

.authors-header h2 {
  font-size: 3.5rem;
  color: #ff4b4b;
  margin-bottom: 1rem;
}

.authors-header p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.meet-authors-btn {
  background: transparent;
  border: 2px solid #ff4b4b;
  color: #ff4b4b;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.meet-authors-btn:hover {
  background: #ff4b4b;
  color: white;
  transform: translateY(-2px);
}

.floating-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.author-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.author-image:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .author-image {
    width: 150px;
    height: 150px;
  }
}

/* Add decorative elements */
.floating-images::before {
  content: '○';
  position: absolute;
  top: 30%;
  right: 15%;
  color: #ffd700;
  font-size: 24px;
  opacity: 0.5;
}

.floating-images::after {
  content: '×';
  position: absolute;
  bottom: 25%;
  right: 40%;
  color: #ff4b4b;
  font-size: 24px;
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .authors {
    padding: 4rem 2rem;
  }

  .authors-header {
    max-width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }

  .authors-image-cluster {
    position: relative;
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .authors-image-cluster {
    height: 300px;
  }

  .author-image.large {
    width: 150px;
    height: 150px;
  }

  .author-image.medium {
    width: 120px;
    height: 120px;
  }

  .author-image.small {
    width: 100px;
    height: 100px;
  }
}

/* Updated Testimonials Section */
.testimonials {
  padding: 4rem 2rem;
  background: #ffffff;
}

.testimonials-container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.testimonial-card {
  padding: 2rem;
  border-radius: 8px;
  color: white;
}

.testimonial-card.red-bg {
  background: #ff4b4b;
}

.testimonial-card.yellow-bg {
  background: #ffc107;
}

.testimonial-quote {
  font-size: 1.3rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  font-weight: 500;
  color: white;
}

.testimonial-author {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: white;
}

.testimonial-author em {
  color: white;
}

.read-story-btn {
  background: white;
  border: none;
  padding: 0.7rem 1.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.red-bg .read-story-btn {
  color: #ff4b4b;
}

.yellow-bg .read-story-btn {
  color: #ffc107;
}

.read-story-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .authors-image-cluster {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    margin-top: 3rem;
  }

  .floating-images {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .testimonials-container {
    grid-template-columns: 1fr;
  }

  .authors-header {
    text-align: center;
  }

  .floating-images {
    width: 300px;
    height: 300px;
  }

  .testimonial-quote {
    font-size: 1.2rem;
  }
} 

/* Book Trailer Section */
.book-trailer {
  padding: 4rem 0;
  background: #f8f9fa;
  width: 100%;
  margin: 0;
}

.trailer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.trailer-content-wrapper {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
}

.trailer-video {
  width: 100%;
  max-width: 600px;
  aspect-ratio: 16 / 9;
  position: relative;
  margin: 0 auto;
}

.trailer-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.trailer-content {
  flex: 1;
  padding: 1rem;
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .trailer-content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .trailer-video {
    max-width: 100%;
  }
} 

/* Remove Standards Section styles and add Consultation Form styles */
.consultation {
  padding: 4rem 2rem;
  background: #f8f9fa;
  text-align: center;
}

.consultation h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 3rem;
}

.consultation-form {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.form-row {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.form-group {
  flex: 1;
}

.form-group.full-width {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.required {
  color: #ff4b4b;
  margin-left: 0.2rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  resize: vertical;
}

.recaptcha {
  margin-bottom: 1.5rem;
}

.submit-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
  
  .consultation h2 {
    font-size: 2.2rem;
  }
} 

.free-guide-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 123, 255, 0.3);
  text-decoration: none;
  display: inline-block;
}

.free-guide-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 123, 255, 0.4);
} 

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.dialog-overlay.show {
  opacity: 1;
  visibility: visible;
}

.success-dialog {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease;
  max-width: 400px;
  width: 90%;
}

.success-dialog.show {
  transform: translateY(0);
  opacity: 1;
}

.checkmark {
  color: #4CAF50;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.success-dialog h3 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.success-dialog p {
  color: #666;
  margin-bottom: 1.5rem;
}

.close-dialog-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.close-dialog-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
} 

.author-story-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.author-story-dialog {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.close-story-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.close-story-btn:hover {
  transform: rotate(90deg);
}

.author-image {
  width: 150px;
  height: 150px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-details {
  text-align: center;
  color: white;
}

.author-details h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: white;
}

.book-title {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  opacity: 0.9;
}

.rating {
  margin-bottom: 1.5rem;
}

.star {
  color: #ffd700;
  margin: 0 0.2rem;
  font-size: 1.5rem;
}

.author-feedback {
  font-size: 1.2rem;
  line-height: 1.6;
  font-style: italic;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .author-story-dialog {
    padding: 1.5rem;
  }

  .author-image {
    width: 100px;
    height: 100px;
  }

  .author-details h2 {
    font-size: 1.5rem;
  }

  .author-feedback {
    font-size: 1rem;
  }
} 

/* Video Popup Styles */
.video-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.video-popup-overlay.show {
  opacity: 1;
  visibility: visible;
}

.video-popup-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: black;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
}

.video-popup-content video {
  width: 100%;
  display: block;
}

.close-video-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1;
}

.close-video-popup:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.mute-button {
  position: absolute;
  top: 10px;
  right: 60px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.mute-button:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .video-popup-content {
    width: 95%;
  }
} 

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%) scale(1.1);
} 

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.1s;
}

/* Update existing badge styles and add new badge styles */
.badge-tag {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 6px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 2;
  color: white;
}

/* Create multiple pulse animations with different timings */
@keyframes pulse1 {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes pulse2 {
  0% { transform: scale(1.05); }
  60% { transform: scale(1.15); }
  100% { transform: scale(1.05); }
}

@keyframes pulse3 {
  0% { transform: scale(0.95); }
  40% { transform: scale(1.1); }
  100% { transform: scale(0.95); }
}

/* Apply different animations to each badge */
.book-wrapper:nth-child(1) .badge-tag {
  animation: pulse1 1.2s infinite;
  animation-delay: 0.1s;
}

.book-wrapper:nth-child(2) .badge-tag {
  animation: pulse2 1.4s infinite;
  animation-delay: 0.3s;
}

.book-wrapper:nth-child(3) .badge-tag {
  animation: pulse3 1.1s infinite;
  animation-delay: 0.2s;
}

.book-wrapper:nth-child(4) .badge-tag {
  animation: pulse2 1.3s infinite;
  animation-delay: 0.4s;
}

.book-wrapper:nth-child(5) .badge-tag {
  animation: pulse3 1.5s infinite;
  animation-delay: 0.15s;
}

.book-wrapper:nth-child(6) .badge-tag {
  animation: pulse1 1.2s infinite;
  animation-delay: 0.25s;
}

/* Keep existing badge styles */
.badge-tag.hot {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.badge-tag.new {
  background: linear-gradient(45deg, #00c6ff, #0072ff);
}

.badge-tag i {
  font-size: 0.9rem;
}

.badge-tag.hot i {
  animation: flame 0.5s infinite alternate;
}

.badge-tag.new i {
  animation: sparkle 0.8s infinite;
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(180deg);
  }
}

@keyframes flame {
  from {
    transform: translateY(0) rotate(-5deg);
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
  }
  to {
    transform: translateY(-2px) rotate(5deg);
    text-shadow: 0 0 12px rgba(255, 255, 255, 0.8);
  }
}