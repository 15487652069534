.contact-page {
  padding-top: 80px;
}

.contact-hero {
  background: var(--gradient-primary);
  padding: 4rem 2rem;
  text-align: center;
  position: relative;
}

.contact-hero h1 {
  color: white;
  font-size: 3.5rem;
  margin: 0;
  font-weight: 600;
}

.hero-underline {
  width: 80px;
  height: 4px;
  background: white;
  margin: 1.5rem auto 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.contact-info-section {
  padding: 4rem 0;
  background: white;
  margin-top: -60px;
  position: relative;
  z-index: 2;
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.info-card {
  background: white;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-10px);
}

.info-icon {
  font-size: 2rem;
  color: var(--primary-start);
  margin-bottom: 1rem;
}

.info-card h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.info-card p {
  color: #666;
  line-height: 1.6;
}

.map-section {
  width: 100%;
  height: 450px;
  padding: 0 2rem;
}

.map-section iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  display: block;
}

.contact-form-section {
  padding: 5rem 0;
  background: #f8f9fa;
}

.form-content {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-header h2 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.form-header p {
  color: #666;
  font-size: 1.1rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-row input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-row input:focus {
  border-color: var(--primary-start);
  outline: none;
}

textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  resize: vertical;
  transition: border-color 0.3s ease;
}

textarea:focus {
  border-color: var(--primary-start);
  outline: none;
}

.submit-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
  width: 100%;
}

.submit-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

@media (max-width: 992px) {
  .info-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .contact-hero h1 {
    font-size: 2.5rem;
  }

  .info-cards {
    grid-template-columns: 1fr;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .form-content {
    padding: 2rem;
  }
}

/* Add these styles for the success dialog */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.dialog-overlay.show {
  opacity: 1;
  visibility: visible;
}

.success-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
  background: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease;
  max-width: 400px;
  width: 90%;
}

.success-dialog.show {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--gradient-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.checkmark svg {
  color: white;
  font-size: 40px;
  animation: scaleIn 0.3s ease;
}

@keyframes scaleIn {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}

.close-dialog-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.close-dialog-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

/* Loading spinner styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--primary-start);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.loading-text {
  color: var(--primary-start);
  font-size: 1.1rem;
  margin-top: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.contact-section h2 {
  font-size: var(--font-size-h2);
}

.contact-info p {
  font-size: var(--font-size-body);
}

.form-row input,
textarea {
  font-size: var(--font-size-body);
}

@media (max-width: 768px) {
  .contact-section h2 {
    font-size: var(--font-size-h2-mobile);
  }

  .contact-info p,
  .form-row input,
  textarea {
    font-size: var(--font-size-body-mobile);
  }
} 