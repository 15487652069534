.publishing-services {
  padding-top: 80px;
}

.process-section {
  padding: 4rem 2rem;
  text-align: center;
}

.process-section h2 {
  font-size: var(--font-size-h2);
  color: #333;
  margin-bottom: 2.5rem;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.step {
  text-align: center;
}

.step-number {
  width: 50px;
  height: 50px;
  background: var(--gradient-primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.step h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: var(--font-size-h3);
}

.step p {
  color: #666;
  font-size: var(--font-size-body);
  line-height: 1.5;
}

.packages-section {
  padding: 4rem 2rem;
  text-align: center;
  background: #f8f9fa;
}

.packages-section h2 {
  font-size: var(--font-size-h2);
  color: #333;
  margin-bottom: 2rem;
}

.package-cards {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 1rem;
}

.package-card {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1;
  max-width: 320px;
  display: flex;
  flex-direction: column;
}

.package-card:hover {
  transform: translateY(-5px);
}

.package-card.featured {
  border: 2px solid var(--primary-color);
  transform: scale(1.05);
}

.package-card.featured:hover {
  transform: scale(1.05) translateY(-5px);
}

.select-package {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
}

.select-package:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 51, 102, 0.3);
}

.package-card h3 {
  font-size: var(--font-size-h3);
  margin-bottom: 0.75rem;
  color: #ff4d6a;
}

.package-description {
  font-size: var(--font-size-body);
  color: #666;
  margin-bottom: 1rem;
  min-height: 40px;
  line-height: 1.5;
}

.price {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.package-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

.package-card ul li {
  font-size: var(--font-size-body);
  margin-bottom: 0.5rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.4;
}

.package-card ul li:before {
  content: "✓";
  color: var(--primary-color);
  font-weight: bold;
}

.contact-form {
  padding: 4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-form h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 3rem;
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.form-row input {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.submit-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

@media (max-width: 992px) {
  .process-steps {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .package-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .package-card {
    width: 100%;
    max-width: 500px;
  }

  .packages-section h2 {
    font-size: 28px;
  }

  .package-card h3 {
    font-size: 20px;
  }

  .price {
    font-size: 28px;
  }

  .package-description,
  .package-card ul li {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .packages-grid {
    grid-template-columns: 1fr;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .process-steps {
    grid-template-columns: 1fr;
  }

  .process-section h2,
  .packages-section h2 {
    font-size: var(--font-size-h2-mobile);
  }

  .step h3,
  .package-card h3 {
    font-size: var(--font-size-h3-mobile);
  }

  .step p,
  .package-description,
  .package-card ul li {
    font-size: var(--font-size-body-mobile);
  }

  .price {
    font-size: 28px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modal-content h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.modal-content h3 {
  color: var(--primary-start);
  margin-bottom: 1.5rem;
}

.payment-notice {
  position: relative;
  background: linear-gradient(135deg, rgba(255, 51, 102, 0.08), rgba(255, 107, 61, 0.08));
  padding: 0.6rem;
  border-radius: 12px;
  margin: 0.3rem 0 1.5rem 0;
  z-index: 1;
}

.payment-notice::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  background: var(--gradient-primary);
  border-radius: 12px;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.payment-notice-header {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 0.8rem;
}

.payment-icon {
  width: 24px;
  height: 24px;
  background: var(--gradient-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
}

.payment-notice h4 {
  color: var(--primary-start);
  font-size: 1.1rem;
  margin: 0;
}

.payment-terms {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.payment-term-item {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  padding: 0;
}

.payment-term-item svg {
  color: var(--primary-start);
  font-size: 1rem;
  margin-top: 0.2rem;
}

.payment-term-item p {
  color: #555;
  font-size: 1rem;
  margin: 0;
  line-height: 1.2;
}

.package-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group {
  margin-bottom: 0.3rem;
}

.form-group input {
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
}

.file-upload {
  border: 2px dashed #ddd;
  padding: 0.6rem;
  border-radius: 5px;
  text-align: center;
  transition: border-color 0.3s ease;
  margin-bottom: 0.3rem;
}

.file-upload:hover {
  border-color: var(--primary-start);
}

.file-upload-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
  font-size: 1rem;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload input[type="file"] + label {
  background: var(--gradient-primary);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease;
  margin: 0.5rem 0;
}

.file-upload input[type="file"] + label:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

.file-hint {
  font-size: 0.8rem;
  color: #666;
  margin-top: 0.2rem;
}

.submit-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 0.6rem;
  font-size: 0.95rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.3rem;
}

.submit-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

.success-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.success-dialog.show {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.success-dialog .checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--gradient-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.success-dialog .checkmark svg {
  color: white;
  font-size: 40px;
  animation: scaleIn 0.3s ease;
}

.success-dialog h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.success-dialog p {
  color: #666;
  margin-bottom: 1.5rem;
}

.success-dialog button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.success-dialog button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1999;
}

.dialog-overlay.show {
  opacity: 1;
  visibility: visible;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-start);
  animation: spin 1s ease-in-out infinite;
}

.loading-text {
  color: white;
  font-size: 1.1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1998;
} 