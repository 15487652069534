.video-header {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-top: -116px;
  background: #000;
}

.vimeo-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.vimeo-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease;
  transform: scale(1.4); /* Increased scale to remove side black bars */
}

.vimeo-wrapper iframe.loaded {
  opacity: 1;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #1a1a1a, #333);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 40px;
}

.contact-button {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 15px 40px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 40px;
  z-index: 2;
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.contact-button:hover {
  background: white;
  color: black;
  transform: translateY(-2px);
  text-decoration: none;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .video-overlay {
    padding: 20px;
  }
  
  .contact-button {
    padding: 12px 30px;
    font-size: 1rem;
    margin-bottom: 20px;
  }
} 

/* Add the bottom fade effect */
.bottom-fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.7) 70%,
    rgba(255, 255, 255, 0.95) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 1;
} 

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  position: relative;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-content .contact-button {
  pointer-events: auto; /* Re-enable clicking for the button */
}

.brand-title {
  font-family: 'Cormorant Garamond', serif;
  font-size: 4.5rem;
  font-weight: 600;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  letter-spacing: 2px;
  line-height: 1.2;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: left;
}

.brand-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100px;
  height: 2px;
  background: linear-gradient(90deg, rgba(255,255,255,0.8), transparent);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .brand-title {
    font-size: 3rem;
  }
}

@media (max-width: 480px) {
  .brand-title {
    font-size: 2.5rem;
  }
}

/* Update the trust badges styles */
.trust-badges {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  padding-left: 0;
  position: relative;
  top: 40px;
}

.trust-badge {
  object-fit: contain;
}

.trust-badge:nth-child(1) {
  margin-left: -20px;
  width: 200px;
  height: 100px;
}

.trust-badge:nth-child(2) {
  margin-left: -80px;
  width: 350px;
  height: 150px;
}

.trust-badge:nth-child(3) {
  margin-left: -60px;
  width: 90px;
  height: 35px;
}

.trustpilot-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.trust-score {
  text-align: left;
}

.stars {
  color: #00b67a; /* Trustpilot green */
  font-size: 1.2rem;
  margin-bottom: 2px;
}

.trust-score p {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.2;
}

.verified {
  color: #00b67a;
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 768px) {
  .trust-badges {
    top: 30px;
  }
  .trust-badge:nth-child(3) {
    width: 75px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .trust-badges {
    top: 20px;
  }
  .trust-badge:nth-child(3) {
    width: 60px;
    height: 25px;
  }
}

/* Add these new styles */
.trustpilot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.verified-company {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #00b67a; /* Trustpilot green color */
  font-size: 0.8rem;
  font-weight: 500;
}

.check-icon {
  font-weight: bold;
}

/* Update existing trust-badge styles if needed */
.trust-badge:nth-child(3) {
  margin-left: -60px;
  width: 90px;
  height: 35px;
} 