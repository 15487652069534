.about-page {
  padding-top: 60px;
}

.about-hero {
  background: var(--gradient-primary);
  color: white;
  padding: 6rem 2rem;
  text-align: center;
}

.about-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.about-hero p {
  font-size: 1.3rem;
  max-width: 800px;
  margin: 0 auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.mission-section {
  padding: 5rem 0;
  background: #fff;
}

.mission-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.mission-section p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.values-section {
  padding: 5rem 0;
  background: #f8f9fa;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.value-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card h3 {
  color: var(--primary-start);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.team-section {
  padding: 5rem 0;
  text-align: center;
}

.contact-section {
  padding: 5rem 0;
  text-align: center;
  background: var(--gradient-primary);
  color: white;
}

.contact-button {
  background: transparent;
  color: white;
  border: 2px solid white;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background: white;
  color: var(--primary-start);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .values-grid {
    grid-template-columns: 1fr;
  }
  
  .about-hero h1 {
    font-size: 2.5rem;
  }
}

.partners-section {
  padding: 5rem 0;
  background: #f8f9fa;
  text-align: center;
}

.partners-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
  position: relative;
}

.partners-section h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background: #ffd700;
  margin: 1rem auto 0;
}

.partners-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.partners-grid img {
  height: 40px;
  object-fit: contain;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.partners-grid img:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .partners-grid {
    gap: 2rem;
  }
  
  .partners-grid img {
    height: 30px;
  }
}

.services-section {
  padding: 5rem 0;
  background: #fff;
  text-align: center;
}

.services-section h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 3rem;
  position: relative;
}

.services-section h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background: #ffd700;
  margin: 1rem auto 0;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 3rem;
}

.service-item {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: auto;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.service-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 4px solid #007bff;
  transition: transform 0.3s ease;
}

.service-item:hover img {
  transform: scale(1.05);
}

.service-content {
  padding: 2rem;
}

.service-item h3 {
  color: var(--primary-start);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.service-item h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background: #ffd700;
}

.service-item p {
  color: #666;
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .service-item {
    max-width: 500px;
    margin: 0 auto;
  }
}

.video-section {
  padding: 4rem 0;
  background: #f8f9fa;
  text-align: center;
}

.video-section h2 {
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.video-section p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
}

.video-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.video-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.vimeo-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.vimeo-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.vimeo-container iframe.loaded {
  opacity: 1;
}

@media (max-width: 768px) {
  .video-wrapper {
    max-width: 100%;
    margin: 0 1rem;
  }
  
  .video-section h2 {
    font-size: 2.2rem;
  }
} 