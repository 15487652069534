.navbar {
  position: sticky;
  top: 36px; /* Height of announcement bar */
  margin-top: 0; /* Remove the margin */
  width: 100%;
  z-index: 1000;
  height: 80px;
  transition: all 0.3s ease;
}

/* Default solid navbar style */
.navbar.solid {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 36px; /* Keep navbar below announcement bar when solid */
}

/* Transparent navbar style for home page top */
.navbar.transparent {
  background-color: transparent;
  box-shadow: none;
}

/* Update text colors based on navbar state */
.navbar.solid .navbar-logo-text {
  color: #1a1a1a;
  text-shadow: none;
}

.navbar.solid .nav-item {
  color: #333333;
  text-shadow: none;
}

.navbar.transparent .navbar-logo-text {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.navbar.transparent .nav-item {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Update hamburger menu colors */
.navbar.solid .hamburger span {
  background-color: #333333;
}

.navbar.transparent .hamburger span {
  background-color: #ffffff;
}

/* Update dropdown styles */
.navbar.solid .dropdown-menu {
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.navbar.transparent .dropdown-menu {
  background: rgba(255, 255, 255, 0.9);
}

/* Mobile menu adjustments */
@media (max-width: 768px) {
  .navbar {
    top: 32px; /* Match announcement bar height on mobile */
  }
  
  .navbar.transparent .navbar-menu.active {
    background-color: rgba(255, 255, 255, 0.95);
  }
  
  .navbar.transparent .navbar-menu.active .nav-item {
    color: #333333;
    text-shadow: none;
  }
  
  .navbar.solid {
    top: 32px;
  }
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar-logo {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-left: auto;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.nav-item:hover {
  color: var(--gradient-primary);
}

.nav-button {
  background: var(--gradient-primary);
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 51, 102, 0.3);
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.nav-item-dropdown {
  position: relative;
  cursor: pointer;
}

.nav-item-dropdown .nav-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  min-width: 200px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item {
  display: block;
  padding: 0.8rem 1.5rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

.dropdown-arrow {
  font-size: 0.8rem;
  margin-left: 0.3rem;
  display: inline-block;
  transition: transform 0.3s ease;
}

.nav-item-dropdown:hover .dropdown-arrow {
  transform: rotate(180deg);
}

.phone-number {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.number {
  font-size: 1.2rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.active {
    display: flex;
  }

  .nav-item-dropdown {
    width: 100%;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    display: none;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-item {
    padding: 0.8rem 2rem;
  }

  .nav-item {
    padding: 0.5rem 0;
  }

  .phone-number {
    margin: 1rem 0;
    justify-content: center;
  }
}

.navbar-brand {
  font-family: 'Cormorant Garamond', serif;
  font-size: 3.2rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  line-height: 0.8;
}

.navbar.solid .navbar-brand {
  opacity: 1;
  color: #333;
}

.navbar.transparent .navbar-brand {
  opacity: 0;
}

/* Updated responsive adjustments for larger base size */
@media (max-width: 768px) {
  .navbar-brand {
    font-size: 2.5rem;
  }
}

.navbar-logo-img {
  height: 40px;
  width: auto;
  object-fit: contain;
  transition: filter 0.3s ease;
  margin-right: 5px;
}

/* White logo and text on video header */
.navbar.transparent .navbar-logo-img {
  filter: brightness(0) invert(1);
}

/* Pink logo when not on video header */
.navbar.solid .navbar-logo-img {
  filter: invert(45%) sepia(82%) saturate(2849%) hue-rotate(325deg) brightness(100%) contrast(101%);
}

.navbar-logo-text {
  font-size: 24px;
  font-weight: 700;
  transition: color 0.3s ease;
}

/* White text on video header */
.navbar.transparent .navbar-logo-text {
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Pink text when not on video header */
.navbar.solid .navbar-logo-text {
  color: #ff3a60;
  text-shadow: none;
}

.gradient-icon {
  background: var(--gradient-primary);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  font-size: 1.5rem;
  color: transparent;
}

.gradient-text {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.nav-links a {
  font-size: var(--font-size-body);
  font-weight: 500;
}

.phone-number {
  font-size: var(--font-size-body);
}

@media (max-width: 768px) {
  .nav-links a,
  .phone-number {
    font-size: var(--font-size-body-mobile);
  }
}

/* Add this for smooth transition when navbar becomes solid */
.navbar {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
} 