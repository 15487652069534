.announcement-bar {
  width: 100%;
  background: var(--gradient-primary);
  color: white;
  text-align: center;
  padding: 8px 20px;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 0.5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
}

.announcement-bar p {
  margin: 0;
  padding: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

@media (max-width: 768px) {
  .announcement-bar {
    font-size: 0.95rem;
    padding: 6px 15px;
  }
} 