:root {
  --gradient-primary: linear-gradient(45deg, #FF3366, #FF6B3D);
  --hover-gradient: linear-gradient(45deg, #FF6B3D, #FF3366);
  --primary-start: #FF3366;
  --primary-end: #FF6B3D;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.gradient-button {
  background: var(--gradient-primary);
  transition: all 0.3s ease;
}

.gradient-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--gradient-primary);
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--hover-gradient);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-start) #f1f1f1;
}

/* For modal scrollbars */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--gradient-primary);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--hover-gradient);
} 