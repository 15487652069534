.google-rating-widget {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #202124;
  border-radius: 16px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 220px;
  cursor: pointer;
  transition: transform 0.2s;
}

.widget-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.google-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.rating-content {
  display: flex;
  flex-direction: column;
}

.rating-label {
  color: white;
  font-size: 0.8rem;
  margin-bottom: 2px;
}

.rating-score {
  display: flex;
  align-items: center;
  gap: 4px;
}

.score {
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
}

.stars {
  color: #ffd700;
  font-size: 0.8rem;
  letter-spacing: -1px;
}

.google-rating-widget:hover {
  transform: scale(1.02);
}

.review-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 400px;
  height: 100vh;
  background-color: #181818;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  animation: slideInLeft 0.3s ease-out;
}

.review-panel.closing {
  animation: slideOutLeft 0.3s ease-out;
}

.review-panel-content {
  height: 100vh;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: white;
  max-height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(51, 51, 51, 0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #444444;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555555;
  }
  scrollbar-width: thin;
  scrollbar-color: #444444 rgba(51, 51, 51, 0.5);
}

.close-panel {
  position: sticky;
  top: 0;
  right: 20px;
  float: right;
  z-index: 2;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.close-panel:hover {
  opacity: 1;
}

h2 {
  font-size: 24px;
  margin-bottom: 24px;
  color: white;
}

.google-reviews-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.google-brand {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.rating-summary {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rating-number {
  font-size: 32px;
  font-weight: bold;
}

.rating-stars {
  font-size: 24px;
  color: #ffd700;
}

.review-count {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
}

.review-button {
  background: #ff6b00;
  color: white;
  text-decoration: none;
  padding: 12px;
  border-radius: 24px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  display: block;
}

.review-button:hover {
  background-color: #ff8533;
}

.ai-summary {
  background: #202124;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
}

.ai-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.ai-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #4040ff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 15px rgba(255,255,255,0.2);
}

.ai-icon svg {
  width: 28px;
  height: 28px;
  filter: drop-shadow(0 0 2px rgba(0,0,0,0.2));
  animation: sparkle 2s infinite;
}

@keyframes sparkle {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.9; }
}

.ai-title h3 {
  color: #4285f4;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.ai-title p {
  color: rgba(255, 255, 255, 0.7);
  margin: 4px 0 0;
  font-size: 14px;
}

.rating-stars.large {
  font-size: 24px;
  margin: 16px 0;
  color: #ffd700;
}

.summary-points {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.summary-points li {
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  white-space: pre-wrap;
  width: 0;
  line-height: 1.5;
}

.summary-points li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: white;
  opacity: 0;
}

.summary-points li:nth-child(1) {
  animation: typing 0.5s steps(40, end) forwards;
}
.summary-points li:nth-child(1)::before {
  animation: fadeIn 0.1s ease-in 0.5s forwards;
}

.summary-points li:nth-child(2) {
  animation: typing 0.5s steps(40, end) 0.5s forwards;
  opacity: 0;
}
.summary-points li:nth-child(2)::before {
  animation: fadeIn 0.1s ease-in 1s forwards;
}

.summary-points li:nth-child(3) {
  animation: typing 0.5s steps(40, end) 1s forwards;
  opacity: 0;
}
.summary-points li:nth-child(3)::before {
  animation: fadeIn 0.1s ease-in 1.5s forwards;
}

@keyframes typing {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 480px) {
  .review-panel {
    width: 100%;
  }
  .summary-points li {
    white-space: normal;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .google-rating-widget {
    bottom: 15px;
    left: 15px;
  }
}

.reviews-section {
  margin: 20px 0;
  padding: 10px 0;
}

.review-item {
  background: #202124;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
}

.review-header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 12px;
}

.reviewer-photo-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.reviewer-photo,
.reviewer-initials {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.reviewer-photo {
  object-fit: cover;
}

.reviewer-initials {
  background-color: #4285f4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}

.reviewer-info {
  flex: 1;
}

.reviewer-info h4 {
  margin: 0 0 4px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.verified-badge {
  display: inline-flex;
  align-items: center;
  margin-left: 2px;
  cursor: help;
  filter: drop-shadow(0 1px 2px rgba(0,0,0,0.2));
}

.verified-badge svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  animation: badgeShine 3s infinite;
}

@keyframes badgeShine {
  0% { filter: brightness(1) }
  50% { filter: brightness(1.2) }
  100% { filter: brightness(1) }
}

.review-rating {
  color: #ffd700;
  margin-bottom: 4px;
  font-size: 14px;
}

.review-time {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  line-height: 1;
}

.review-text {
  margin-top: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.no-reviews {
  color: white;
  text-align: center;
  padding: 20px;
}

/* Update text colors */
.google-reviews-header span,
.rating-number,
.rating-stars,
.review-count,
h2,
.reviewer-info h4,
.review-text,
.ai-title p,
.summary-points li {
  color: white;
}

/* Ensure AI summary section text is white */
.ai-summary {
  color: white;
}

/* Make sure the close button is visible */
.close-panel {
  color: white;
}

/* Add these styles for the reviewer name link */
.reviewer-name-link {
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
}

.reviewer-name-link:hover {
  color: #4285f4; /* Google blue color on hover */
  text-decoration: underline;
}

.review-meta {
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.review-source {
  color: #ff6b00;
  font-size: 12px;
  line-height: 1;
}

.reviewer-name-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

/* Update the Google Reviews container styling */
.google-reviews-container {
  background: #202124;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
}

.google-logo {
  height: 28px;
  width: auto;
  object-fit: contain;
  max-width: 95px;
  display: block;
}

.reviews-text {
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin-left: 0;
  line-height: 28px;
}

.rating-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.rating-value {
  color: white;
  font-size: 32px;
  font-weight: 700;
}

.rating-stars {
  color: #ffd700;
  font-size: 24px;
  letter-spacing: -1px;
}

.review-count {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
}

.review-button {
  background: #ff6b00;
  color: white;
  text-decoration: none;
  padding: 12px;
  border-radius: 24px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  display: block;
}

/* AI Summary Container styling */
.ai-summary-container {
  background: #202124;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
}

.ai-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.ai-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #4040ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-sparkle-icon {
  width: 24px;
  height: 24px;
}

.ai-title h3 {
  color: #4285f4;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.ai-title p {
  color: rgba(255, 255, 255, 0.7);
  margin: 4px 0 0;
  font-size: 14px;
}

.ai-rating {
  color: #ffd700;
  font-size: 24px;
  margin-bottom: 16px;
  letter-spacing: -1px;
}

.summary-points {
  list-style: none;
  padding: 0;
  margin: 0;
}

.summary-points li {
  color: white;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
  line-height: 1.4;
}

.summary-points li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: white;
}

/* Review items styling */
.review-item {
  background: #202124;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
} 