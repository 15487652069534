.marketing-services {
  padding-top: 80px;
}

.hero-section {
  padding: 6rem 2rem;
  text-align: center;
  background: var(--gradient-primary);
  color: white;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  color: white;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  opacity: 0.9;
  color: white;
}

.services-grid {
  padding: 5rem 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.service-card h2 {
  color: var(--primary-start);
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.service-description {
  color: #666;
  line-height: 1.6;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.service-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.service-bullets li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.8rem;
  color: #444;
  line-height: 1.4;
}

.service-bullets li::before {
  content: "•";
  color: var(--primary-start);
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

.cta-section {
  padding: 5rem 2rem;
  text-align: center;
  background: #f8f9fa;
}

.contact-form {
  padding: 5rem 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: #333;
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.submit-button {
  background: var(--gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.submit-button:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
}

.cta-button-outline {
  background: transparent;
  color: var(--primary-start);
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}

.cta-button-outline::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  padding: 2px;
  background: var(--gradient-primary);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.cta-button-outline:hover {
  background: var(--gradient-primary);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 51, 102, 0.2);
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .service-card {
    padding: 2rem;
  }
  
  .service-card h2 {
    font-size: 1.5rem;
  }
  
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.consultation-form {
  margin-top: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input,
.date-picker {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.modal-content h2 {
  color: #333;
  margin-bottom: 0.5rem;
}

.modal-content p {
  color: #666;
  margin-bottom: 1.5rem;
}

/* Custom styles for the date picker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
} 